/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { connect } from "react-redux";
import Layout from "~/components/layout";
import SEO from "~/components/seo";
import NewArrivalsCourses from "~/components/newArrivalsCourses";
import Product from "~/components/shoppingCart/product";
import { bindActionCreators } from "redux";
import { removeFromCartAction } from "~/redux/actions/cart_actions";
import Link from "~/components/link";
import usePrices from "~/hooks/usePrices";
import useOrderSummary from "~/hooks/useOrderSummary";
import PricesContext from "~/providers/pricesProvider";
import loading from "~/images/loading.svg";
import ownPace from "~/images/why-us/own-pace.svg";
import certificate from "~/images/why-us/certificate.svg";
import Loader from "~/components/loader";
import navigate from "~/components/navigate";
import { isClient } from "~/utils/app-helpers";
import CouponBox from "~/components/couponBox";

const Cart = (props) => {
  const [cart, setCart] = useState(null);
  let carChangeCheck = JSON.stringify(props.cart);

  const i18n = props.i18n;
  const paths = props.pageContext.paths;

  // ACA es para los precios
  let ids = [];
  if (Object.keys(props.cart).length > 0)
    ids = props.cart.products.map((course) => course.ID);
  const joinedIds = ids.join(",");
  // ------------------------

  const { data: myPrices } = usePrices(joinedIds);

  const { loaded, prices, total, subtotal, discount } = useOrderSummary(
    myPrices
  );

  useEffect(() => {
    setCart(JSON.parse(carChangeCheck));
    // updatePrices();

    return () => {
      return false;
    };

  }, [carChangeCheck, myPrices]);

  // ---------------------------------

  const handleGoToCheckout = (e) => {
    e.preventDefault();

    if (isClient) {
      sessionStorage.setItem("vtCart", JSON.stringify(cart));
    }

    navigate("/checkout", props.pageContext.locale);
  };

  return (
    <Layout paths={paths}>
      <SEO
        title="Cart"
        keywords={[`cart`, `shopping`, `bag`]}
        location={paths}
      />
      <div className="p-3 sm:p-4 md:p-4 md:py-6  border-b border-gray-200">
        <div className="md:mr-auto md:ml-auto lg:max-w-6xl">
          <h3 className="md:mb-4 mb-1 text-sm md:text-xl hidden md:block">
            <Trans>Courses in the cart</Trans>:{" "}
            {cart !== null ? (
              `${cart.count}`
            ) : (
              <Loader reverse={true} className="m-0 inline-block" />
            )}
          </h3>
          {cart !== null && cart.products.length > 0 ? (
            <div className="flex flex-col md:justify-between md:space-x-12 md:flex-row">
              <div className="md:flex-1">
                <button
                  onClick={handleGoToCheckout}
                  disabled={!loaded}
                  className="btn btn-primary w-full block text-center md:hidden mb-4"
                >
                  <span className="flex items-center space-x-4 justify-center">
                    <span>
                      <Trans>Continue to checkout</Trans>
                    </span>
                    <span className="icon icon-angle-right" />
                  </span>
                </button>
                <h3 className="mb-2 text-sm md:hidden">
                  <Trans>Courses in the cart</Trans>: {cart.count}
                </h3>
                <div className="w-full" id="cart_items">
                  <ul className="space-y-3">
                    {cart.products.map((product, index) => {
                      const funcCart = (product) => (e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        props.onRemoveItem(product);
                      };

                      return (
                        <li key={index + "_cart_item"}>
                          <PricesContext.Provider value={prices}>
                            <Product
                              {...product}
                              {...props}
                              funcCart={funcCart}
                            />
                          </PricesContext.Provider>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="mt-4 md:mt-6 space-y-2 hidden md:block">
                    <div className="flex items-center space-x-4 leading-none bg-white shadow rounded-brand p-4">
                      <img
                        src={certificate}
                        alt="Completion Certificate"
                        className="inline-block w-12"
                      />
                      <span>
                        <h3 className="text-gray-600 text-sm md:text-lg">
                          <Trans>Completion Certificate</Trans>
                        </h3>
                        <p className="mt-2 md:text-left text-xs text-gray-600 md:text-sm">
                          <Trans>
                            Accreditation for your attendance to the courses
                            with a certificate of completion.
                          </Trans>
                        </p>
                      </span>
                    </div>
                    <div className="flex items-center space-x-4 leading-none bg-white shadow rounded-brand p-4">
                      <img
                        src={ownPace}
                        alt="Learn at your own pace"
                        className="inline-block w-12"
                      />
                      <span>
                        <h3 className="text-gray-600 text-sm md:text-lg">
                          <Trans>Learn at your own pace</Trans>
                        </h3>
                        <p className="mt-2 md:text-left text-xs text-gray-600 md:text-sm">
                          <Trans>
                            Enjoy all of the courses from home, without
                            schedules or deadlines. You make your learning
                            experience the way you want it to be.
                          </Trans>
                        </p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white shadow rounded-brand p-2 border border-gray-300 md:p-8 mb-4 md:mb-0 md:self-start md:w-1/3 mt-4 md:mt-0">
                <h3>
                  <Trans>Order summary</Trans>
                </h3>
                {loaded && discount !== 0 && (
                  <>
                    <div className="flex items-center justify-between mt-1 md:mt-4 text-gray-600">
                      <span>
                        <Trans>Original price</Trans>:
                      </span>
                      <span className="line-through text-sm md:text-base">
                        {subtotal}
                      </span>
                    </div>
                    <div className="flex items-center justify-between">
                      <span className="text-gray-600">
                        <Trans>Discount</Trans>:
                      </span>
                      <span className="text-red-400 text-sm md:text-base">
                        {discount}
                      </span>
                    </div>
                  </>
                )}
                <div className="flex items-center justify-between mt-2 pt-2 border-t border-gray-200">
                  <span className="text-xl">Total:</span>
                  <span className="text-xl md:text-3xl font-bold text-brand-primary align-middle">
                    {loaded ? (
                      <>
                        {} {total.total} {total.symbol}
                      </>
                    ) : (
                      <img src={loading} alt={i18n._(t`Loading`)} />
                    )}
                  </span>
                </div>
                <div className="text-right mt-2 md:mb-0 md:mt-4">
                  <button
                    onClick={handleGoToCheckout}
                    disabled={!loaded}
                    className="btn btn-primary w-full block text-center"
                  >
                    <span className="flex items-center space-x-4 justify-center">
                      <span>
                        <Trans>Continue to checkout</Trans>
                      </span>{" "}
                      <span className="icon icon-angle-right" />
                    </span>
                  </button>
                  <CouponBox disable={prices === ""} />
                </div>
              </div>
            </div>
          ) : (
            <NoItemsInCart />
          )}
          <NewArrivals />
        </div>
      </div>
    </Layout>
  );
};

const NewArrivals = () => {
  return (
    <div className="hidden md:block mt-6">
      <div className="text-center">
        <h2>
          <Trans>New courses</Trans>
        </h2>
      </div>
      <div className="mt-2">
        <NewArrivalsCourses />
      </div>
    </div>
  );
};

const NoItemsInCart = () => {
  return (
    <div className="text-center p-10" id="no_items">
      <p>
        <Trans>No items in the cart</Trans>
      </p>
      <Link
        to="/courses"
        className="no-underline btn btn-primary py-2 xl:py-3 inline-block w-full md:w-1/3 md:text-2xl mt-4"
      >
        <span className="mr-2 align-middle lg:tracking-wide">
          <Trans>View all courses</Trans>
        </span>
        <span className="icon-chevron-right text-xs ml-2 font-bold" />
      </Link>
    </div>
  );
};

const mapStateToProps = ({ cart }) => {
  return {
    cart,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(
    {
      onRemoveItem: removeFromCartAction,
    },
    dispatch
  );
};

export default withI18n()(connect(mapStateToProps, mapActionsToProps)(Cart));
