/* eslint-disable no-restricted-globals */
import React, { useState, useRef, useEffect, useContext } from "react";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import { useForm } from "~/hooks/useForm";
import { isClient, objectIsEmpty } from "~/utils/app-helpers";
import { CheckoutContext } from "~/providers/checkoutProvider";

const CouponBox = ({ i18n, disable }) => {
  // para el mostrar/ocultar el campo de cupon
  const [showCouponField, setCouponFieldVisibility] = useState(false);

  const [state] = useContext(CheckoutContext);
  const { coupon, total } = state;

  const inputRef = useRef();
  const [values, handleChange] = useForm({
    coupon: "",
  });

  useEffect(() => {
    if (showCouponField && isClient) {
      inputRef.current.focus();
    }

    return () => {
      return false;
    };
  }, [coupon, showCouponField, handleChange]);

  const applyCoupon = (e) => {
    e.preventDefault();

    if (isClient) {
      sessionStorage.setItem("vtCoupon", values.coupon);
      location.reload();
    }
  };

  const removeCoupon = (e) => {
    e.preventDefault();

    if (isClient) {
      sessionStorage.removeItem("vtCoupon");
      location.reload();
    }
  };

  const showInput = (e) => {
    e.preventDefault();
    setCouponFieldVisibility(true);
  };

  if (objectIsEmpty(total)) return false;

  return (
    <div className="text-right md:mb-0 md:mt-4">
      {coupon !== null ? (
        <div className="md:mt-4 mt-2 flex justify-between items-center bg-gray-200 text-gray-800 px-4 py-2 rounded-brand font-medium">
          <span>
            <Trans>Coupon applied</Trans>:{" "}
            <span className="uppercase">{coupon}</span>
          </span>
          <span>
            <button
              title={i18n._(t`Remove coupon`)}
              onClick={removeCoupon}
              disabled={disable}
            >
              <span className="icon icon-times" />
            </button>
          </span>
        </div>
      ) : (
        <>
          <button
            className={`w-full flex justify-between mt-1 md:mt-3 font-medium bg-gray-200 px-4 py-2 rounded-brand ${
              showCouponField && "hidden"
            }`}
            onClick={showInput}
          >
            <span>
              <Trans>Add coupon code</Trans>
            </span>
            <span>+</span>
          </button>

          <div
            className={`flex border border-gray-400 inputBox w-full rounded-full bg-white hover:shadow-focus mt-1 md:mt-3 ${
              showCouponField ? "block" : "hidden"
            }`}
          >
            <form className="w-full flex">
              <input
                type="text"
                name="coupon"
                id="coupon"
                className="w-full p-2 px-0 ml-5 text-sm"
                placeholder={i18n._(t`Enter coupon code`)}
                onChange={handleChange}
                ref={inputRef}
                autoComplete="off"
              />
              <button
                type="submit"
                className="btn-secundary p-2 font-medium m-1 mr-4"
                disabled={disable}
                onClick={applyCoupon}
              >
                <Trans>APPLY</Trans>
              </button>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default withI18n()(CouponBox);
