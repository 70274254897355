import { useState, useEffect } from "react";

export default function useOrderSummary(myPrices) {
  const [loaded, setStatus] = useState(false);
  const [prices, setPrices] = useState(null);
  const [total, setTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [mixed, setMixed] = useState(false);
  const [multiSubs, setMultiSubs] = useState(false);
  const [couponValid, setCouponValid] = useState(false);

  const updatePrices = (myPrices) => {
    setPrices(null);
    setStatus(false);
    setSubtotal(0);
    setDiscount(0);

    if (Object.keys(myPrices).length > 0) {
      setPrices(myPrices);
      setStatus(true);

      let subtotal = 0;
      let total = 0;
      let productType = null;
      let subForeverFree = false;
      let subForeverFreeCounter = 0;
      const symbol = myPrices.currency.symbol;
      const coin = myPrices.currency.coin;

      Object.keys(myPrices).forEach(function (product) {
        if (!isNaN(product)) {
          let price = Number(myPrices[product].price);
          let old_price =
            typeof myPrices[product].price_old !== "undefined"
              ? Number(myPrices[product].price_old)
              : 0;

          let priceDefault = price > 0 ? price : 0;
          let oldPriceDefault = old_price > 0 ? old_price : 0;

          if (
            typeof myPrices[product].sub_details !== "undefined" &&
            myPrices[product].sub_details !== ""
          ) {
            if (myPrices[product].sub_details.period_price !== "free") {
              subtotal = subtotal + parseFloat(myPrices[product].price);
              total =
                total + parseFloat(myPrices[product].sub_details.period_price);
            } else {
              if (
                myPrices[product].sub_details.period_price === "free" &&
                myPrices[product].sub_details.period_amount === "-1"
              ) {
                ++subForeverFreeCounter;
              }
            }
          } else {
            if (myPrices[product].price_old === "")
              subtotal = subtotal + parseFloat(priceDefault);
            else subtotal = subtotal + parseFloat(oldPriceDefault);

            total = total + parseFloat(priceDefault);

            if (myPrices[product].sub_details === "" && price === 0) {
              ++subForeverFreeCounter;
            }
          }

          if (productType === null) {
            productType = myPrices[product].type;
          } else {
            if (productType !== myPrices[product].type) {
              setMixed(true);
            }
          }

          if (myPrices[product].coupon) {
            setCouponValid(true);
          }
        }
      });

      const howManySubs = Object.values(myPrices).reduce(function (
        totalSubs,
        course
      ) {
        if (course.type === "monthly_subscription") {
          return totalSubs + 1;
        }
        return totalSubs;
      },
      0);

      if (howManySubs > 1) {
        setMultiSubs(true);
      }

      let discount = subtotal.toFixed(2) - total.toFixed(2);
      if (discount !== 0)
        setDiscount(
          "-" +
            (subtotal.toFixed(2) - total.toFixed(2)).toFixed(2) +
            "  " +
            symbol
        );

      const howManyCourses = Object.keys(myPrices).reduce((acum, item) => {
        return !isNaN(item) ? ++acum : acum;
      }, 0);

      subForeverFree = howManyCourses === subForeverFreeCounter;

      setTotal({
        total: total.toFixed(2),
        symbol: symbol,
        coin: coin,
        productType: productType,
        subForeverFree,
      });
      setSubtotal(subtotal.toFixed(2) + " " + symbol);
    }
  };

  useEffect(() => {
    updatePrices(myPrices);

    return () => {
      return false;
    };
  }, [myPrices]);

  return {
    loaded,
    prices,
    total,
    subtotal,
    discount,
    mixed,
    multiSubs,
    couponValid,
  };
}
