import { useState } from "react";

export const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);

  return [
    values,
    (e) => {
      if (typeof e.target === "undefined") {
        setValues({
          ...values,
          ...e,
        });
      } else {
        const { name, value, type, checked } = e.target;

        let myValue;

        if (type === "checkbox") {
          myValue = +checked;
        } else {
          myValue = isNaN(value) || value === "" ? value : Number(value);
        }

        setValues({
          ...values,
          [name]: myValue,
        });
      }
    },
  ];
};
