import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Carrousel from "~/components/Carrousel";
import PricesContext from "../providers/pricesProvider";
import * as actionCreators from "../redux/actions/cart_actions";
import usePrices from "../hooks/usePrices";
import { shuffleArray } from "../utils/app-helpers";

const NewArrivalsCourses = ({ cart, removeFromCartAction, addToCartAction }) => {
  const coursesList = useNewArrivalsCourses().edges;

  const [courses, setCourses] = useState([]);

  useEffect(() => {
    if (coursesList.length > 0) setCourses(shuffleArray(coursesList));

    return () => {
      return false;
    };
  }, [coursesList]);

  // ACA es para los precios
  const ids = courses.map((course) => course.node.ID);
  const joinedIds = ids.join(",");
  // ------------------------

  const { data } = usePrices(joinedIds);

  const CarrouselData = {
    courses,
    cart,
    removeFromCartAction,
    addToCartAction,
  };

  return (
    <PricesContext.Provider value={data}>
      <Carrousel {...CarrouselData} />
    </PricesContext.Provider>
  );
};

const useNewArrivalsCourses = () => {
  const data = useStaticQuery(graphql`
    query NewArrivalsCourses {
      allVtutorStructuredContent(sort: { fields: created_at, order: DESC }, filter: { free: { eq: false } }, limit: 16) {
        edges {
          node {
            ID
            image
            language
            tutor {
              name
              avatar
            }
            title
            students
            reviews_avg
            reviews {
              count
            }
            free
            promo_acceptable
            permalink {
              category
              slug
            }
            total_lectures
            duration
            created_at
          }
        }
      }
    }
  `);

  return data.allVtutorStructuredContent;
};

const mapStateToProps = ({ cart }) => {
  return {
    cart,
  };
};

const mapActionsToProps = (dispatch) => {
  return bindActionCreators(actionCreators, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(NewArrivalsCourses);
