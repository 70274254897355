import React, { useState } from "react";

const CheckoutContext = React.createContext([{}, () => {}]);

const CheckoutProvider = (props) => {
  const [state, setState] = useState(
    props.data
      ? props.data
      : {
          total: {},
          courses: "",
          coursesData: {},
          country: null,
          mixed: false,
          multiSubs: false,
          success: false,
          lang: null,
          ga: null,
          coupon: null,
          promo: true,
          orders: null,
          paymentMethod: {
            method: null,
            payment_id: null,
          },
          name: null,
          email: null,
          gtm: { items: {} },
          utm_src: null,
        }
  );

  return <CheckoutContext.Provider value={[state, setState]}>{props.children}</CheckoutContext.Provider>;
};

export { CheckoutProvider, CheckoutContext };
