import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import PricesContext from "../../providers/pricesProvider";
import Period from "../transHelpers/period";
import Link from "../link";
import Loader from "../loader";

const Product = ({
  ID,
  image,
  title,
  funcCart,
  link,
  showRemove = true,
  ...props
}) => {
  const prices = JSON.stringify(useContext(PricesContext));
  const [price, set_price] = useState("");
  const [price_old, set_price_old] = useState("");
  const [price_type, set_price_type] = useState("");
  const [details, set_details] = useState("");
  const [symbol, set_symbol] = useState("");

  useEffect(() => {
    const dec_prices = JSON.parse(prices);

    if (dec_prices !== null && dec_prices[ID] !== undefined) {
      set_price(dec_prices[ID].price);
      set_price_old(dec_prices[ID].price_old);
      set_price_type(dec_prices[ID].type);
      set_details(dec_prices[ID].sub_details);
      set_symbol(dec_prices.currency.symbol);
    }

    return () => {
      return false;
    };
  }, [prices, ID]);

  const isDescounted = () => {
    return price_old !== "" && price !== price_old;
  };

  return (
    <Link
      to={link}
      className="flex justify-center items-stretch bg-white shadow rounded-brand w-full"
    >
      <div className="w-26">
        <img
          src={`https://res.cloudinary.com/ie1/image/fetch/f_auto,w_225,h_150,c_fill,g_auto,q_auto:best/${image}`}
          title={title}
          alt={title}
          style={{ borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}
          className="w-24 h-full md:w-40"
        />
      </div>
      <div className="px-2 md:px-4 flex-1">
        <div className="flex flex-col pt-1 md:pt-2 h-full">
          <div className="leading-none mb-2 text-xs md:text-base">{title}</div>
          <div className="text-right mt-auto pb-1 md:pb-2 leading-tight text-xs md:text-base">
            {price === "" ? (
              <div className="flex justify-end">
                <Loader reverse={true} className="m-0" />
              </div>
            ) : (
              <>
                {Number(price) > 0 ? (
                  <>
                    {isDescounted() && (
                      <div className="text-xs mb-1 mt-1">
                        <span className="bg-green-200 border border-green-200 p-1 px-2 mr-2 text-green-700 rounded-brand ">
                          {Math.floor(
                            100 - (Number(price) * 100) / Number(price_old)
                          )}
                          % OFF
                        </span>
                        <span className="line-through md:text-sm text-gray-600">
                          {price_old} {symbol}
                        </span>
                      </div>
                    )}
                    <span className="font-bold text-brand-primary whitespace-nowrap md:text-lg">
                      {details !== "" && (
                        <>
                          {details.period_amount === "-1" ? (
                            <div className="line-through text-gray-700 font-light">
                              {price} /{" "}
                              {details.period === "week" ? (
                                <Trans>week</Trans>
                              ) : (
                                <Trans>month</Trans>
                              )}
                            </div>
                          ) : (
                            <div>
                              {details.period_amount}{" "}
                              <Period
                                amount={details.period_amount}
                                period={details.period}
                              />{" "}
                              {details.period_price}{" "}
                              {details.period_price !== "free" && symbol}
                            </div>
                          )}
                        </>
                      )}
                      {details !== "" && details.period_amount === "-1" ? (
                        <>
                          {details.period_price === "free" ? (
                            <>
                              <Trans>Free</Trans>
                            </>
                          ) : (
                            <>
                              {details.period_price} {symbol} /{" "}
                              {details.period === "week" ? (
                                <Trans>week</Trans>
                              ) : (
                                <Trans>month</Trans>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {details !== "" && (
                            <>
                              <Trans>then</Trans>{" "}
                            </>
                          )}
                          {price} {symbol}
                          {price_type !== "one_time_payment" && (
                            <>
                              {price_type === "monthly_subscription" && (
                                <span>
                                  {" "}
                                  / <Trans>month</Trans>
                                </span>
                              )}
                              {price_type === "weekly_subscription" && (
                                <span>
                                  {" "}
                                  / <Trans>week</Trans>
                                </span>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </span>
                  </>
                ) : (
                  <div className="font-bold text-brand-primary leading-none text-sm md:text-base">
                    <Trans>Free</Trans>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {showRemove && (
        <div className="py-4 text-xl flex items-center">
          <div className="w-8 md:w-12 border-l border-gray-300 h-full flex items-center justify-center">
            <button
              onClick={funcCart({ ID })}
              className="text-gray-400 h-full w-full"
            >
              <i className="icon icon-times" />
            </button>
          </div>
        </div>
      )}
    </Link>
  );
};

Product.propTypes = {
  ID: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  funcCart: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired
};

export default Product;
